/* theme colors*/
:root {
  --c-bg1: white; //white
  --c-text: darkslategrey; //noir
  --c-accent: #006D6E; //turquoise bg
  --c-accent2: #04A898; //teal light
  --c-bg: #e5fcf7; //teal
  --width1: 20em;
  --width2: 45em;
  --pad: .5em;
  --mar: 1em;
}

@media (prefers-color-scheme: dark) {
  :root{
    --c-bg1: black; //white
    --c-text: #e5fcf7; //noir
    --c-accent: #006D6E; //turquoise bg
    --c-accent2: #04A898; //teal light
    --c-bg: #112222; //teal
  }

  img {
    filter: brightness(50%);
  }
  header img {
    filter: brightness(75%);
  }
}

html {
  color: var(--c-text);
  background-color: var(--c-bg1);

}

body {
font-family: martel, verdana, serif;
margin: 0;
background-color: var(--c-bg);
}

div {
  background-color: var(--c-bg);
  border-top: var(--c-accent2) dotted thin;
  border-bottom: var(--c-accent2) dotted thin;
  padding: var(--pad)
}

h1, h2, h3 {
  text-align: center;
  line-height: 2em;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--c-accent);
  }

h1 {
 color: var(--c-accent2);
}

h3 {
  border-top: thin solid var(--c-accent);
  border-bottom: thin solid var(--c-accent);
}

p {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}

a, a:active {
  color: var(--c-accent);
  text-decoration-style: dotted;
  text-decoration-color: var(--c-accent2);
  font-weight: bold;
}

a:visited {
  color: var(--c-accent2);
  text-decoration-style: dashed;
  text-decoration-color: var(--text-accent2);
}

a, a:active {
  color: var(--c-accent);
  text-decoration: none;
}

header, footer {
  background-color: var(--c-accent);
  padding: 0;
  color: white;
}

footer {
  padding: var(--mar);
}

header nav {
  padding-left: 120px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  column-gap: var(--mar);
}

@media screen and (min-width:40em) {
  header nav {
    flex-flow: row wrap;
    justify-content: flex-start;
  }
}

header nav a, header nav a:visited, footer a, footer a:visited {
 color: white;
 margin: var(--mar);
 padding: var(--mar);
}

header nav a:hover, footer a:hover {
background-color: #112222;
}

header img {
  position: absolute;
  left: var(--pad);
  top: var(--pad);
  text-align:center;
  width: 100px;
  padding: var(--mar);
  z-index: 1;
}

.hero {
  width: 100%;
  object-fit: cover;
  height: 20em;
  width: 100%;
  padding: 0;
}

main {
  font-size: 1.2em;
  line-height: 1.5em;
}

article, section a {
  background-color: var(--c-bg1);
  margin: auto;
  padding: 0;
  width : var(--width2);
  border-top: var(--c-text) 12px solid;
  margin-top: -4px;
}

section {
  display: flex;
  flex-flow: row wrap;
  column-gap: var(--mar);
  row-gap: var(--mar);
  align-items: stretch;
  justify-content: space-around;
}

section a, section a:visited {
  width: var(--width1);
  color: var(--c-text);
}

section a:hover {
  color: var(--c-accent2);
    border-top: var(--c-accent2) 12px solid;
}

article img {
  width: 120%;
  transform: translateX(-8%);
}

article div {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

article div a {
  width: 45%;
  text-align: center;
}

::selection {
  background: var(--c-bg);
  color: black;
  text-shadow: none;
}
